
import Vue from "vue";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { debounce } from "lodash";
const { VUE_APP_MONGO_CHARTS_URL } = process.env;
import { chartMapActions, chartMapState } from "@/store/modules/chart/index";
interface IChartData {
  ready: boolean;
  chartData: any;
  enlargeSingleChart: boolean;
  mongoSDK: any;
  overrides: any;
  chartDomId: string;
}
export default Vue.extend({
  props: {
    chart: {
      type: Object,
      default() {
        return {};
      }
    },
    allowFullScreen: {
      type: Boolean,
      default: true
    },
    fitParent: {
      type: Array,
      default() {
        return [];
      }
    },
    exportChartData: {
      default: true,
      type: Boolean
    },
    filter: {
      default() {
        return {};
      },
      type: Object
    }
  },
  data(): IChartData {
    return {
      ready: false,
      chartData: null,
      enlargeSingleChart: false,
      mongoSDK: new ChartsEmbedSDK({
        baseUrl: VUE_APP_MONGO_CHARTS_URL,
        getUserToken: () => localStorage.getItem("access") as any
      }),
      overrides: null,
      chartDomId: `chart-${Math.random()
        .toString(36)
        .substring(2, 15)}`
    };
  },
  computed: {
    ...chartMapState(["makingApiRequest"])
  },
  mounted() {
    this.initOverrides();
    this.initChart();
    window.addEventListener(
      "resize",
      debounce(() => {
        if (this.initOverrides()) {
          this.initChart();
        }
      }, 150)
    );
  },
  methods: {
    ...chartMapActions(["exportChart"]),
    initOverrides(): boolean {
      if (this.fitParent.length && this.$refs && this.$refs.chartItem) {
        const parent = (this.$refs.chartItem as any).parentElement;
        if (parent) {
          this.overrides = {};
          if (this.fitParent.includes("width")) {
            this.overrides["width"] =
              parent.clientWidth - parent.clientWidth * (10 / 100);
          }
          if (this.fitParent.includes("height")) {
            this.overrides["height"] = parent.clientHeight;
          }
          return true;
        }
      }
      this.overrides = null;
      return false;
    },
    async initChart() {
      try {
        this.ready = false;
        const chart = this.$refs[this.chartDomId];
        if (chart) {
          let chartData = this.chart;
          if (this.overrides) {
            chartData = { ...chartData, ...this.overrides };
          }
          this.chartData = this.mongoSDK.createChart({
            ...chartData
          });

          await this.chartData.render(chart);
          if (Object.keys(this.filter)) this.chartData.setFilter(this.filter);
          await this.chartData.addEventListener("click", () => {
            if (this.allowFullScreen) {
              this.enlargeSingleChart = true;
              setTimeout(async () => {
                let enlargedChartsSDK = this.mongoSDK.createChart({
                  ...chartData,
                  height: 900,
                  width: 1200
                });
                await enlargedChartsSDK.render(this.$refs["enlarge"]);
                this.enlargeSingleChart = true;
              }, 100);
            }
          });
        }
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.ready = true;
      }
    },
    async exportTheChartData() {
      const filter = await this.chartData.getFilter();
      try {
        await this.exportChart({ id: this.chart.chartId, filter });
      } catch (error) {
        this.$bugSnagClient.notify(error);
        this.$appNotifyError(error.message);
      }
    },
    closedDialog() {
      (this.$refs["enlarge"] as any).innerHTML = "";
    }
  },
  watch: {
    chart: {
      deep: true,
      handler(data) {
        if (data) {
          this.initOverrides();
          this.initChart();
        }
      }
    },
    filter: {
      handler(data) {
        if (data) {
          this.initOverrides();
          this.initChart();
        }
      }
    }
  }
});
